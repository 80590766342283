
    import Vue from 'vue'
    import { Component, Watch } from 'vue-property-decorator'
    import HttpClient from '@/lib/http-client'
    import { ITransKomText } from '@/models/data/transkom'
    import { services as Api } from '@/api'
    import Notifications from '@/lib/notifications'

    @Component
    export default class ComponentTransKomSearch extends Vue {
        translation = ''
        translations: Array<ITransKomText> = []
        showSelect = true
        editMode = true
        progress = 1
        loading = true

        async loadTranslations (): Promise<void> {
            this.loading = true
            try {
                this.translations = await HttpClient.getJson<ITransKomText[]>(Api.transkom.texts)
            } catch (e) {
                Notifications.error(this.$t('transkom.translations_loading_error') as string)
            } finally {
                this.loading = false
            }
        }

        doReset (): void {
            this.showSelect = true
            this.translation = ''
        }

        reset (event: KeyboardEvent): void {
            if (event.ctrlKey && event.key === 'e') {
                event.preventDefault()
                this.doReset()
            }
        }

        accept (): void {
            this.$emit('selected', this.translation)
            this.showSelect = true
            this.translation = ''
        }

        mounted (): void {
            this.loadTranslations()
            document.addEventListener('keydown', this.reset)
        }

        @Watch('showSelect')
        onShowSelectChange (): void {
            if (this.showSelect) {
                this.progress = 1
            } else {
                this.progress = 2
            }
        }

        @Watch('editMode')
        onEditModeChange (): void {
            if (this.editMode) {
                document.addEventListener('keydown', this.reset)
            } else {
                document.removeEventListener('keydown', this.reset)
            }
        }
    }
