
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import Templates from '@/store/modules/templates'
    import { Param } from '@/models/data/template'
    import Notifications from '@/lib/notifications'

    class Snippet {
        text = ''
        value = ''
    }

    @Component
    export default class ComponentSnippets extends Vue {
        templatesModule = getModule(Templates, this.$store)

        menuProps = {
            closeOnClick: true,
            closeOnContentClick: true,
            disableKeys: true,
            openOnClick: false,
            maxHeight: 304,
            offsetY: true,
            offsetOverflow: true,
            transition: 'slide-y-transition'
        }

        loading = true

        snippet = new Snippet()

        get items (): Array<Param> {
            return this.templatesModule.params
        }

        accept (): void {
            const value = `{{${this.snippet.value}}}`
            this.$emit('selected', value)
            this.snippet = new Snippet()
        }

        async loadParams (): Promise<void> {
            this.loading = true
            try {
                await this.templatesModule.loadParams()
            } catch (e) {
                Notifications.error('Wystąpił problem przy ładowaniu parametrów szablonów wiadomości.')
            }
            this.loading = false
        }

        async mounted (): Promise<void> {
            window.addEventListener('keydown', (event: KeyboardEvent) => {
                if (event.key === 'Enter' && event.ctrlKey) {
                    event.preventDefault()
                    this.accept()
                }
            })

            await this.loadParams()
        }
    }
