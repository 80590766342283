
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import { debounce as Debounce } from 'typescript-debounce-decorator'
    import HttpClient from '@/lib/http-client'
    import { ITranslation } from '@/models/data/translate'
    import { services as Api } from '@/api'
    import Notifications from '@/lib/notifications'

    @Component
    export default class ComponentTranslateBox extends Vue {
        loading = false
        languages = [
            {
                text: 'polski',
                value: 'PL',
            },
            {
                text: 'angielski',
                value: 'EN',
            },
            {
                text: 'niemiecki',
                value: 'DE',
            }
        ]

        sourceText = ''
        sourceLang = 'PL'
        targetLang = 'DE'
        translatedText = ''

        @Debounce(1000, { leading: false })
        async translate (): Promise<void> {
            if (this.sourceText.length === 0) {
                this.translatedText = ''
                return
            }

            this.loading = true

            const urlSearchParams = new URLSearchParams({
                text: this.sourceText,
                source_lang: this.sourceLang,
                target_lang: this.targetLang
            })
            const query = '?' + urlSearchParams.toString()

            try {
                const response = await HttpClient.getJson<ITranslation>(Api.deepl.translate + query)
                this.translatedText = response.text
            } catch (e) {
                Notifications.error('Wystąpił problem podczas tłumaczenia.')
            } finally {
                this.loading = false
            }
        }

        accept (): void {
            this.$emit('selected', this.translatedText)
            this.sourceText = ''
            this.translatedText = ''
        }
    }
